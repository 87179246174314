<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <DataTable
            :value="reservas_cartao"
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :loading="loading"
            dataKey="id"
            :filters.sync="filtros"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} reservas de cartão"
            responsiveLayout="scroll">
            <template #empty> Nenhuma reserva de cartão encontrada. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <Column header="Cadastrado Em">
              <template #body="{ data }">
                {{ data.auditMetadata.cadastradoEm | formatarData }}
              </template>
            </Column>
            <Column header="Cadastrado Por">
              <template #body="{ data }">
                {{ data.cadastradoPor ? data.cadastradoPor : ' - ' }}
              </template>
            </Column>
            <Column header="Cancelado Em">
              <template #body="{ data }">
                {{ data.dataCancelamento | formatarData }}
              </template>
            </Column>
            <Column header="Cancelado Por">
              <template #body="{ data }">
                {{ data.canceladoPor ? data.canceladoPor : ' - ' }}
              </template>
            </Column>
            <Column
              field="consignataria.nomeFantasia"
              header="Consignatária"></Column>
            <Column field="valor" header="Valor">
              <template #body="{ data }">
                <div class="flex justify-content-end flex-wrap card-container">
                  <div class="flex align-items-center justify-content-center">
                    {{ data.valor | formatarValor }}
                  </div>
                </div>
              </template>
            </Column>
            <Column header="Status">
              <template #body="slotProps">
                <Chip
                  :label="labelChip(slotProps.data.codigoStatusReservaCartao)"
                  :class="
                    classChip(slotProps.data.codigoStatusReservaCartao)
                  " />
              </template>
            </Column>
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  v-tooltip.left="'Histórico'"
                  icon="pi pi-list"
                  class="p-button-rounded p-button"
                  @click="exibirHistorico(data.historico)" />
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
      <Dialog
        :visible.sync="modalHistorico"
        :containerStyle="{ width: '50vw' }">
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="historico"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
          responsiveLayout="scroll">
          <template #header>Histórico</template>
          <template #empty> Nenhum histórico encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Alterado Em">
            <template #body="{ data }">
              {{ data.alteradoEm | formatarData }}
            </template>
          </Column>
          <Column header="Alterado Por">
            <template #body="{ data }">
              {{ data.nomeAlteradoPor }}
            </template>
          </Column>
          <Column field="descricaoStatus" header="Status" />
        </DataTable>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ReservaCartaoService from '@/service/ReservaCartaoService.js'

export default {
  data() {
    return {
      reservas_cartao: [],
      reservaCartao: {},
      loading: false,
      totalRecords: 0,
      filtros: {},
      modalHistorico: false,
      historico: [],
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.reservaCartaoService = new ReservaCartaoService(this.$http)
  },

  mounted() {
    this.loading = true
    this.reservaCartaoService
      .getListaReservaCartaoPorMatricula(this.$route.params.id)
      .then((res) => {
        this.reservas_cartao = res
        this.loading = false
      })
      .catch((err) => {
        this.loading = false
        this.$toast.add({
          severity: 'error',
          summary: err.response.data.message,
          life: 10000,
        })
      })
  },

  methods: {
    initFiltros() {
      this.filtros = {
        'statusConsignacao.nome': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    limparFiltros() {
      this.initFiltros()
    },

    formatarData(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    },

    formatarValor(value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },

    exibirHistorico(historico) {
      this.historico = historico
      this.modalHistorico = true
    },

    labelChip(codigo) {
      if (codigo === 1) {
        return 'Ativo'
      } else if (codigo === 2) {
        return 'Suspensa'
      } else if (codigo === 3) {
        return 'Cancelada'
      }
    },

    classChip(codigo) {
      if (codigo === 1) {
        return 'mr-2 mb-2 custom-chip ativa'
      } else if (codigo === 2) {
        return 'mr-2 mb-2 custom-chip suspensa'
      } else if (codigo === 3) {
        return 'mr-2 mb-2 custom-chip cancelada'
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
}

.ativa {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
  text-align: center;
}

.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
  text-align: center;
}
</style>
